import BaseError from './BaseError'

export default class WrongImportHeadersError extends BaseError<{
	expectedHeaders: string[]
}> {
	httpCode = 500

	getMessage(): string {
		return 'Wrong import headers. Expected ' + this.params.expectedHeaders.join(', ')
	}
}
