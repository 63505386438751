import { Component, Vue } from 'vue-property-decorator'

@Component({})
export class ReactiveProps extends Vue {
	propsReactive<S extends Vue = this, T = Record<string, any>>(mapper: {
		[P in keyof T]?: keyof S | (() => any)
	}) {
		const props = {}
		for (const key of Object.keys(mapper)) {
			const getter = mapper[key]
			if (typeof getter === 'function') {
				Object.defineProperty(props, key, {
					enumerable: true,
					get: getter,
				})
			} else {
				Object.defineProperty(props, key, {
					enumerable: true,
					get: () => this[getter],
				})
			}
		}

		return props
	}
}
