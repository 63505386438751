import BaseError from './BaseError'

export default class FeatureNotSupportedError extends BaseError<{
	endpoint?: string
	feature?: string
}> {
	httpCode = 501
	getMessage(): string {
		let msg = 'Feature is not supported'
		if (this.params.endpoint) {
			msg += ' Endpoint: ' + this.params.endpoint
		}
		if (this.params.feature) {
			msg += 'Feature: ' + this.params.feature
		}
		return msg
	}
}
