import BaseError from './BaseError'

export default class BackingServiceUnavailableError extends BaseError<{
	serviceName: string
	previous?: Error
}> {
	httpCode = 500

	getMessage(): string {
		return 'Backing service named ' + this.params.serviceName + ' is currently unvailable'
	}
}
