import { Api, UserSettings } from '~/shared'
import { prop } from 'ramda'

import { apiClient } from './client'

export const get = (): Promise<Api.Response<UserSettings>> =>
	apiClient.get('/settings').then(prop('data'))

export const patch = (newValue: UserSettings): Promise<void> =>
	apiClient.patch('/settings', newValue, {
		headers: {
			'content-type': 'application/json',
		},
	})
