export const getEndpoint = async (context) => {
  const endpointList = context.rootState.endpoints.endpoints
  const currentEndpoint =
    endpointList.find((e) => e.id === localStorage.getItem('agmir_endpoint')) || endpointList[0]
  return currentEndpoint.id
}

export const getEndpointByStore = (store) => {
  const endpointList = store.state.endpoints.endpoints
  const currentEndpoint =
    endpointList.find((e) => e.id === localStorage.getItem('agmir_endpoint')) || endpointList[0]
  return currentEndpoint.id
}