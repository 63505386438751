import { ReferenceValue } from './types'
import { Schema } from './schema'
import { AttributeStaticProps, ReferenceStaticProps } from './new-types'

export const prefixMapping = {
	'http://www.w3.org/1999/02/22-rdf-syntax-ns#': 'rdf:',
	'http://www.w3.org/2000/01/rdf-schema#': 'rdfs:',
	'http://www.w3.org/2002/07/owl#': 'owl:',
	'http://www.w3.org/2001/XMLSchema#': 'xsd:',
	'http://trinidata.ru/archigraph-mdm/': 'archigraph:',
}

export enum GENERAL {
	uri = 'uri',
	action = 'action',
	classId = 'ClassId'
}

export enum XSD {
	string = 'http://www.w3.org/2001/XMLSchema#string',
	integer = 'http://www.w3.org/2001/XMLSchema#integer',
	date = 'http://www.w3.org/2001/XMLSchema#date',
	dateTime = 'http://www.w3.org/2001/XMLSchema#dateTime',
	boolean = 'http://www.w3.org/2001/XMLSchema#boolean',
	double = 'http://www.w3.org/2001/XMLSchema#double',
}

export enum RDFS {
	label = 'http://www.w3.org/2000/01/rdf-schema#label',
	comment = 'http://www.w3.org/2000/01/rdf-schema#comment',
	domain = 'http://www.w3.org/2000/01/rdf-schema#domain',
	range = 'http://www.w3.org/2000/01/rdf-schema#range',
	subClassOf = 'http://www.w3.org/2000/01/rdf-schema#subClassOf',
	isDefinedBy = 'http://www.w3.org/2000/01/rdf-schema#isDefinedBy',
	seeAlso = 'http://www.w3.org/2000/01/rdf-schema#seeAlso',
}

export enum RDF {
	type = 'http://www.w3.org/1999/02/22-rdf-syntax-ns#type',
	first = 'http://www.w3.org/1999/02/22-rdf-syntax-ns#first',
	rest = 'http://www.w3.org/1999/02/22-rdf-syntax-ns#rest',
	nill = 'http://www.w3.org/1999/02/22-rdf-syntax-ns#nil',
	Description = 'http://www.w3.org/1999/02/22-rdf-syntax-ns#Description',
}

export enum ARCHIGRAPH {
	duplicate = 'http://trinidata.ru/archigraph-mdm/duplicate',
	archive = 'http://trinidata.ru/archigraph-mdm/archive',
	type = 'http://trinidata.ru/archigraph-mdm/type',
	readOnly = 'http://trinidata.ru/archigraph-mdm/readOnly',
	readOnlyLock = 'http://trinidata.ru/archigraph-mdm/readOnlyLock',
	PointType = 'http://trinidata.ru/archigraph-mdm/PointType',
	MultiPolygonType = 'http://trinidata.ru/archigraph-mdm/MultiPolygonType',
	Prefix = 'http://trinidata.ru/archigraph-mdm/prefix',
	LocalCode = 'http://trinidata.ru/archigraph-mdm/LocalCode',
	SourceSystem = 'http://trinidata.ru/archigraph-mdm/SourceSystem',
	nonMultilang = 'http://trinidata.ru/archigraph-mdm/nonMultilang',
	shaclRoot = 'http://trinidata.ru/archigraph-mdm/shaclRoot',
}

export enum OWL {
	minCardinality = 'http://www.w3.org/2002/07/owl#minCardinality',
	maxCardinality = 'http://www.w3.org/2002/07/owl#maxCardinality',
	Class = 'http://www.w3.org/2002/07/owl#Class',
	ObjectProperty = 'http://www.w3.org/2002/07/owl#ObjectProperty',
	DatatypeProperty = 'http://www.w3.org/2002/07/owl#DatatypeProperty',
	Attribute = 'http://www.w3.org/2002/07/owl#Attribute',
	NamedIndividual = 'http://www.w3.org/2002/07/owl#NamedIndividual',
	Restriction = 'http://www.w3.org/2002/07/owl#Restriction',
	sameAs = 'http://www.w3.org/2002/07/owl#sameAs',
	unionOf = 'http://www.w3.org/2002/07/owl#unionOf',
	onProperty = 'http://www.w3.org/2002/07/owl#onProperty',
	intersectionOf = 'http://www.w3.org/2002/07/owl#intersectionOf',
	Ontology = 'http://www.w3.org/2002/07/owl#Ontology',
}

export enum SHACL {
	SPARQLConstraint = 'http://www.w3.org/ns/shacl#SPARQLConstraint',
	SPARQLRule = 'http://www.w3.org/ns/shacl#SPARQLRule',
	Shape = 'http://www.w3.org/ns/shacl#Shape',
	targetClass = 'http://www.w3.org/ns/shacl#targetClass',
	message = 'http://www.w3.org/ns/shacl#message',
	select = 'http://www.w3.org/ns/shacl#select',
	construct = 'http://www.w3.org/ns/shacl#construct',
	ValidationResult = 'http://www.w3.org/ns/shacl#ValidationResult',
	focusNode = 'http://www.w3.org/ns/shacl#focusNode',
	sourceConstraintComponent = 'http://www.w3.org/ns/shacl#sourceConstraintComponent',
	sparql = 'http://www.w3.org/ns/shacl#sparql',
	sourceShape = 'http://www.w3.org/ns/shacl#sourceShape',
}

export enum PURL {
	title = 'http://purl.org/dc/terms/title',
	created = 'http://purl.org/dc/terms/created',
	modified = 'http://purl.org/dc/terms/modified',
	creator = 'http://purl.org/dc/terms/creator',
	contributor = 'http://purl.org/dc/terms/contributor'
}

export enum InputType {
	text = 'text',
	textArea = 'textArea',
	number = 'number',
	select = 'select',
	checkBox = 'checkBox',
	autoComplete = 'autoComplete',
	date = 'date',
	dateTime = 'dateTime',
	dateInterval = 'dateInterval',
	dateTimeInterval = 'dateTimeInterval',
	void = 'void',
}

export type ObjectType = OWL.Class | OWL.NamedIndividual | OWL.DatatypeProperty | OWL.ObjectProperty

export const DEFAULT_OBJECT_ATTRIBUTE_ID_LIST: string[] = [
	RDFS.label,
	RDFS.comment,
	RDFS.isDefinedBy,
	ARCHIGRAPH.archive,
	ARCHIGRAPH.SourceSystem,
	ARCHIGRAPH.LocalCode,
]

type CommonProps = {
	[RDFS.label]: string
	[RDFS.comment]: string
	[ARCHIGRAPH.archive]: boolean
	[RDFS.isDefinedBy]: ReferenceValue[]
}

export const commonSchema: Schema<CommonProps> = {
	[RDFS.label]: {
		literal: XSD.string,
		minCar: 1,
		maxCar: 1,
	},
	[RDFS.comment]: {
		literal: XSD.string,
		minCar: 0,
		maxCar: 1,
		inputType: InputType.textArea,
	},
	[ARCHIGRAPH.archive]: {
		literal: XSD.boolean,
		minCar: 1,
		maxCar: 1,
	},
	[RDFS.isDefinedBy]: {
		reference: [{ type: OWL.NamedIndividual }],
		minCar: null,
		maxCar: null,
		multiple: true,
	},
}

export const commonAttributeSchema: Schema<AttributeStaticProps> = {
	...commonSchema,
	[RDFS.domain]: {
		reference: [{ type: OWL.Class }],
		minCar: 1,
		maxCar: null,
		multiple: true,
	},
	[RDFS.range]: {
		reference: [{ type: OWL.Class }],
		minCar: 1,
		maxCar: null,
		multiple: false,
	},
	[OWL.minCardinality]: {
		literal: XSD.integer,
		minCar: 0,
		maxCar: 1,
	},
	[OWL.maxCardinality]: {
		literal: XSD.integer,
		minCar: 0,
		maxCar: 1,
	},
}

export const referenceAttributeSchema: Schema<ReferenceStaticProps> = {
	...commonAttributeSchema,
	[RDFS.range]: {
		reference: [{ type: OWL.Class }],
		minCar: 1,
		maxCar: null,
		multiple: true,
	},
}

export const literalAttributeSchema: Schema<AttributeStaticProps> = {
	...commonAttributeSchema,
	[RDFS.range]: {
		reference: [{ type: ARCHIGRAPH.type }],
		minCar: 1,
		maxCar: null,
		multiple: false,
	},
}

export type ClassStaticProps = {
	[RDFS.label]: string
	[RDFS.subClassOf]: ReferenceValue[]
	[RDFS.isDefinedBy]: ReferenceValue[]
	[RDFS.comment]: string
	[ARCHIGRAPH.archive]: boolean
}

export type ClassShallowProps = Omit<ClassStaticProps, RDFS.comment | RDFS.isDefinedBy>

export type NamedIndividualStaticProps = {
	[RDFS.label]: string
	[RDF.type]: ReferenceValue[]
	[RDFS.isDefinedBy]: ReferenceValue[]
	[RDFS.comment]: string
	[ARCHIGRAPH.archive]: boolean
}

export const classSchema: Schema<ClassStaticProps> = {
	...commonSchema,
	[RDFS.subClassOf]: {
		reference: [{ type: OWL.Class }],
		minCar: null,
		maxCar: null,
		multiple: true,
	},
}

export const basicNamedIndividualSchema: Schema<NamedIndividualStaticProps> = {
	[RDFS.label]: {
		literal: XSD.string,
		minCar: 1,
		maxCar: 1,
	},
	[RDF.type]: {
		reference: [{ type: OWL.Class }],
		multiple: true,
		minCar: 1,
		maxCar: null,
	},
	[RDFS.comment]: {
		literal: XSD.string,
		multiple: false,
		minCar: 0,
		maxCar: 1,
		inputType: InputType.textArea,
	},
	[ARCHIGRAPH.archive]: {
		literal: XSD.boolean,
		minCar: 1,
		maxCar: 1,
	},
	[RDFS.isDefinedBy]: {
		reference: [{ type: OWL.NamedIndividual }],
		minCar: null,
		maxCar: null,
		multiple: true,
	},
}

export type Constant = Record<string, boolean>