import BaseError from './BaseError'

export default class CacheBuildError<T extends Error> extends BaseError<{
	previous: T
}> {
	httpCode = 500

	getMessage(): string {
		return `Cache build error: ${this.params.previous.name}: ${this.params.previous.message}`
	}
}
