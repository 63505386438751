import { OWL } from '~/shared'
import { RouteConfig } from 'vue-router'

const route: RouteConfig[] = [
	{
		path: '/endpoint/:endpoint',
		component: () => import('@/views/Workspace/Workspace.vue'),
		children: [
			{
				path: 'history',
				component: () => import('@/views/Workspace/views/History.vue'),
			},
			{
				path: 'exchange',
				component: () => import('@/views/Workspace/views/Exchange/Exchange.vue'),
			},
			{
				path: 'metrics',
				component: () => import('@/views/Workspace/views/Metrics/Metrics.vue'),
			},
			{
				path: 'owl',
				component: () => import('@/views/Workspace/views/OwlTurtle/Owl'),
			},
			{
				path: 'ntriples',
				component: () => import('@/views/Workspace/views/OwlTurtle/Turtle'),
			},
			{
				path: 'rolf-reports',
				component: () => import('@/views/Workspace/views/Exchange/RolfReportsExportPage.vue'),
			},
			{
				path: 'exchange-builder',
				component: () => import('@/views/Workspace/views/Exchange/ExchangeBuilder.vue'),
			},
			// {
			// 	path: 'exchange-model',
			// 	component: () => import('@/views/Workspace/views/Exchange/ExchangeModelPage.vue'),
			// },
			{
				path: 'xlsx',
				component: () => import('@/views/Workspace/views/Exchange/ExchangeXLSX.vue'),
			},
			{
				path: 'common-reports',
				component: () => import('@/views/Workspace/views/Exchange/CommonReportsExportPage.vue'),
			},
			{
				path: 'docx',
				component: () => import('@/views/Workspace/views/Exchange/ExchangeDOCX.vue'),
			},
			{
				path: '/',
				component: () => import('./decoratedOntology'),
				children: [
					{
						path: 'class/:class/literals',
						component: () =>
							import('@/views/Workspace/views/Ontology/ItemsBrowser/AttributesBrowser'),
						name: 'editorMainClassLiterals',
						props: {
							tab: 'literals',
							type: OWL.DatatypeProperty,
						},
					},
					{
						path: 'class/:class/references',
						component: () =>
							import('@/views/Workspace/views/Ontology/ItemsBrowser/AttributesBrowser'),
						name: 'editorMainClassReferences',
						props: {
							tab: 'references',
							type: OWL.ObjectProperty,
						},
					},
					{
						path: 'class/:class/individuals',
						name: 'editorMainClassIndividuals',
						component: () =>
							import('@/views/Workspace/views/Ontology/ItemsBrowser/IndividualsBrowser'),
						children: [
							{
								path: 'group-edit',
								name: 'editorMainClassIndividualsGroupEdit',
								component: () =>
									import('@/views/Workspace/views/Ontology/GroupEdit/GroupEditorWindow'),
							},
						],
					},
					{
						path: '/',
						name: 'editor',
						component: () => import('@/views/Workspace/views/Ontology/ItemsBrowser/EmptyBrowser'),
					},
				],
			},
			{
				path: 'ontology-versions',
				component: () => import('@/views/Workspace/views/OntologyVersions.vue'),
			},
		],
	},
	{
		path: '/endpoint',
		component: () => import('@/views/Workspace/Workspace.vue'),
		name: 'editorEmpty',
	},
]

export default route
