import { Component, Mixins, Watch } from 'vue-property-decorator'
import type { Api } from '~/shared'
import { NotAuthenticatedError, UserSettings } from '~/shared'

import { InjectReactive, ProvideReactive, Provide } from '@/vue-extensions'
import { INJECTIONS } from '@/injections'
import { AuthState, Status } from '@/constants'
import { get, patch } from '@/api/settings'
import HandlesErrors from '@/providers/HandlesErrors'
type User = Api.User

@Component({})
export default class SettingsProvider extends Mixins(HandlesErrors) {
	@InjectReactive(INJECTIONS.AUTH.STATE) readonly authState: AuthState
	@InjectReactive(INJECTIONS.AUTH.USER) readonly user: User

	@ProvideReactive(INJECTIONS.settings.data) data: UserSettings = null
	@ProvideReactive(INJECTIONS.settings.state) state: Status = Status.ready

	created() {
		if (this.authState === AuthState.authenticated) {
			this.loadSettings()
		}
	}

	@Watch('authState') onConfigStatusChange(newStatus: AuthState) {
		if (newStatus === AuthState.authenticated) {
			this.loadSettings()
		}
	}

	async loadSettings() {
		this.state = Status.pending
		try {
			const settingsResponse = await get()
			this.data = settingsResponse.data
			this.state = Status.success
		} catch (e) {
			if (!(e instanceof NotAuthenticatedError)) {
				this.handleError(e)
			}
			this.state = Status.failure
		}
	}

	@ProvideReactive(INJECTIONS.timezone.current)
	get currentTimezone() {
		return this.data && this.data.timezone
	}

	@Provide(INJECTIONS.settings.patch) async patchSettings(newSettings: UserSettings) {
		await patch(newSettings)
		this.data = newSettings
	}

	render() {
		return this.$slots.default
	}
}
