import { GetterTree, MutationTree, ActionTree } from 'vuex'
import { apiClient } from '@/api/client'

class State {
	items: string[] = []
	value = false
}

const getters: GetterTree<State, any> = {}

const mutations: MutationTree<State> = {
	add(state, payload: string) {
		state.items.push(payload)
	},
	remove(state, payload: string) {
		state.items = state.items.filter((e) => e !== payload)
	},
	set(state, payload: string[]) {
		state.items = payload
	},
	setValue(state, payload: boolean) {
		state.value = payload
	},
}

const actions: ActionTree<State, any> = {
	async toggle(context, payload: string) {
		const action = context.state.items.includes(payload) ? 'remove' : 'add'
		context.commit(action, payload)
		context.dispatch(action, payload)
	},
	async add(context, payload: string) {
		const { data } = await apiClient.post('favorite/' + encodeURIComponent(payload))
		context.commit('set', data)
	},
	async remove(context, payload: string) {
		const { data } = await apiClient.delete('favorite/' + encodeURIComponent(payload))
		context.commit('set', data)
	},
	async init(context) {
		const { data } = await apiClient.get('favorite')
		context.commit('set', data)
	},
}

export default {
	namespaced: true,
	state: new State(),
	mutations: mutations,
	actions: actions,
	getters: getters,
}
