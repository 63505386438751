import axios from 'axios'
import { v4 as uuid } from 'uuid'

import { API_BASE_URL } from '@/constants'
import { errorTypes, UnknownServerError } from '~/shared'
import { NetworkError } from '@/core/error-handling'

export const apiClient = axios.create({
	withCredentials: true,
	baseURL: API_BASE_URL,
})

apiClient.interceptors.request.use((config) => {
	return Promise.resolve({
		...config,
		headers: {
			...config.headers,
			'Connection-Id': window._CONNECTION_ID_,
			'X-Request-Id': uuid(),
		},
	})
})

apiClient.interceptors.response.use(undefined, (error) => {
	if (!error.isAxiosError) {
		throw error
	}
	if (error.response) {
		const { data } = error.response
		console.log(data)

		if (data.isAppError) {
			const eClass = errorTypes[data.name]
			if (!eClass) {
				let message = data.params?.previous?.message
				if (message?.indexOf('Worker thread:') === 0) {
					throw new Error(message)
				}
				message = data.message || data.params?.message
				if (message) {
					throw new Error(message)
				} else {
					throw new UnknownServerError({ previous: error })
				}
			}
			throw new eClass(data.params)
		} else {
			throw new UnknownServerError({ previous: error })
		}
	} else if (error.code === 'ERR_CANCELED') {
		throw new Error('ERR_CANCELED')
	} else {
		throw new NetworkError({ previous: error })
	}
})

