import {GetterTree, MutationTree, ActionTree} from 'vuex'
import Vue from 'vue'
import Toast from '@/components/Common/Toast'
import notifications from "@/notifications";
import {getUiLang} from "@/utils/lang";

export const toast = (payload: any) => {
  let props

  if (payload.id) {
    let message = notifications[payload.id]
    if (!message) return
    message = message[getUiLang().toLowerCase()]
    if (typeof message.content === 'function') {
      message.content = message.content(payload.arg)
    }
    props = message
  }
  else {
    props = payload
  }

  const content = {
    component: Toast,
    props
  }
  Vue.$toast(content, 'default')
}


class State {
  notifications: { id: string; payload?: any }[] = []
}

const getters: GetterTree<State, any> = {}

const mutations: MutationTree<State> = {
  add(state, payload: { id: string; payload?: any } | string) {
    const v = typeof payload === 'string' ? {id: payload} : payload
    state.notifications.push(v)
  },
  clear(state) {
    state.notifications = []
  },
  toast(context, payload) {
    // let props
    //
    // if (payload.id) {
    //   let message = notifications[payload.id]
    //   if (!message) return
    //   message = message[getUiLang().toLowerCase()]
    //   if (typeof message.content === 'function') {
    //     message.content = message.content(payload.arg)
    //   }
    //   props = message
    // }
    // else {
    //   props = payload
    // }
    //
    // const content = {
    //   component: Toast,
    //   props
    // }
    // this._vm.$toast(content, 'default')
    toast(payload)
  }
}

const actions: ActionTree<State, any> = {}

export default {
  namespaced: true,
  state: new State(),
  mutations: mutations,
  actions: actions,
  getters: getters,
}
