import { BaseError } from '~/shared'

export type SocketErrorPrams<T extends Error> = {
	previous: T
	connectionId: string
	params: Record<string, any>
}
export class SocketError<T extends Error> extends BaseError<SocketErrorPrams<T>> {
	getMessage(): string {
		//@ts-ignore
		return `Socket error: ${this.params.previous.name} ${this.params.previous.message}`
	}
}
