export default abstract class BaseError<P> extends Error {
	name: string
	params: P

	isAppError = true
	httpCode = 500
	message = null

	abstract getMessage(): string

	constructor(params: P) {
		super()
		Object.defineProperty(this, 'name', {
			value: new.target.name,
			enumerable: false,
			configurable: true,
		})
		this.params = params
		if (typeof this.getMessage !== 'undefined') {
			this.message = this.getMessage()
		}
	}
}
