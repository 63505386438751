import {GetterTree, MutationTree, ActionTree} from 'vuex'
import {apiClient} from "@/api/client";
import {Api} from "~/shared";

class State {
  access: false
  user: Api.User = {groups: [], id: 0, login: "", name: "", originator: {originator: ""}, uiLang: ""}
}

const getters: GetterTree<State, any> = {
  access: (state) => state.access,
  user: (state) => state.user
}

const mutations: MutationTree<State> = {
  setAccess: (state, payload) => state.access = payload,
  setUser: (state, payload) => state.user = payload
}

const actions: ActionTree<State, any> = {
  async check(context) {
    const response = (await apiClient.get('/auth/check')).data
    context.commit('setUser', response.user)
    context.commit('setAccess', response.access)
  }

}

export default {
  namespaced: true,
  state: new State(),
  mutations: mutations,
  actions: actions,
  getters: getters,
}
