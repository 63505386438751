import { OWL, RDFS, ReferenceValue, SHACL, XSD } from './'
import { Entity } from './new-types'
import { Schema } from './schema'

export type ValidationResultStaticProps = {
	[SHACL.sourceShape]: ReferenceValue
	[SHACL.focusNode]: ReferenceValue
}
export type ValidationResult = Entity<ValidationResultStaticProps>

export const validationResultSchema: Schema<ValidationResultStaticProps> = {
	[SHACL.sourceShape]: {
		minCar: null,
		maxCar: 1,
		multiple: false,
		reference: [{ type: OWL.NamedIndividual }],
	},
	[SHACL.focusNode]: {
		minCar: null,
		maxCar: null,
		multiple: false,
		reference: [{ type: OWL.NamedIndividual }],
	},
}

export type SPARQLConstraintStaticProps = {
	[SHACL.message]: string
	[SHACL.select]: string
}

export type SPARQLConstraint = Entity<SPARQLConstraintStaticProps>

export const sparqlConstraintSchema: Schema<SPARQLConstraintStaticProps> = {
	[SHACL.message]: {
		minCar: null,
		maxCar: null,
		multiple: false,
		literal: XSD.string,
	},
	[SHACL.select]: {
		minCar: null,
		maxCar: null,
		multiple: false,
		literal: XSD.string,
	},
}

export type SPARQLRuleStaticProps = {
	[RDFS.label]: string
	[SHACL.construct]: string
}

export type SPARQLRule = Entity<SPARQLRuleStaticProps>

export const spartqlRuleSchema: Schema<SPARQLRuleStaticProps> = {
	[RDFS.label]: {
		minCar: null,
		maxCar: null,
		multiple: false,
		literal: XSD.string,
	},
	[SHACL.construct]: {
		minCar: null,
		maxCar: null,
		multiple: false,
		literal: XSD.string,
	},
}

export type ShapeStaticProps = {
	[SHACL.targetClass]: ReferenceValue
	[SHACL.sparql]: ReferenceValue
}

export type Shape = Entity<ShapeStaticProps>

export const shapeSchema: Schema<ShapeStaticProps> = {
	[SHACL.targetClass]: {
		minCar: 1,
		maxCar: 1,
		multiple: false,
		reference: [{ type: OWL.NamedIndividual }],
	},
	[SHACL.sparql]: {
		minCar: 1,
		maxCar: 1,
		multiple: false,
		reference: [{ type: OWL.NamedIndividual }],
	},
}
