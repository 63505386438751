import { GetterTree, MutationTree, ActionTree } from 'vuex'

class State {
	received: string[] = []
	waiting: string[] = []
}

const getters: GetterTree<State, any> = {
	isCompleted(state): boolean {
		return !!state.waiting.length && state.waiting.every((e) => state.received.includes(e))
	},
}

const mutations: MutationTree<State> = {
	setWaiting(state, payload: string[]) {
		state.waiting = payload
	},
	addReceived(state, payload: string) {
		state.received.push(payload)
	},
	reset(state) {
		state.received = []
		state.waiting = []
	},
}

const actions: ActionTree<State, any> = {}

export default {
	namespaced: true,
	state: new State(),
	mutations: mutations,
	actions: actions,
	getters: getters,
}
