import Vue, { ComponentOptions as OComponentOptions } from 'vue'
import { createDecorator } from 'vue-class-component'
import {
	DefaultComputed,
	DefaultData,
	DefaultMethods,
	DefaultProps,
	PropsDefinition,
} from 'vue/types/options'
import { Translations } from '@/i18n.mixin'

declare global {
	interface Window {
		_CONNECTION_ID_: string
	}
}

export interface ComponentOptions<
	V extends Vue,
	Data = DefaultData<V>,
	Methods = DefaultMethods<V>,
	Computed = DefaultComputed,
	PropsDef = PropsDefinition<DefaultProps>,
	Props = DefaultProps
> extends OComponentOptions<V, Data, Methods, Computed, PropsDef, Props> {
	lang?: Translations
}

declare module 'vue/types/options' {
	interface ComponentOptions<V extends Vue> {
		lang?: Translations
	}
}

const extendProvide = (
	original: () => object,
	localKey: string,
	providedKey?: string
): (() => object) => {
	return function () {
		const originalObject = original.call(this)
		const reactive = Object.create(originalObject.reactive || null)
		if (!this.drilled) {
			for (const key in this.reactive) {
				Object.defineProperty(reactive, key, {
					enumerable: true,
					get: () => this.reactive[key],
				})
			}
			this.drilled = true
		}
		const key = providedKey || localKey
		if (key in reactive) {
			console.warn('Reassigned reactive injection: ' + key)
			console.info(this)
		}
		Object.defineProperty(reactive, key, {
			enumerable: true,
			get: () => this[localKey],
		})
		originalObject.reactive = reactive
		return originalObject
	}
}

export const decorateProvideReactive =
	(key: string, providedKey?: string) => (componentOptions: ComponentOptions<Vue>) => {
		const reactiveInjection = {
			reactive: {
				from: 'reactive',
				default: {},
			},
		}
		if (typeof componentOptions.inject === 'object') {
			componentOptions.inject = {
				...componentOptions.inject,
				...reactiveInjection,
			}
		} else {
			componentOptions.inject = reactiveInjection
		}
		const original =
			typeof componentOptions.provide === 'function'
				? (componentOptions.provide as () => object)
				: typeof componentOptions.provide === 'object'
				? function () {
						return componentOptions.provide
				  }
				: function () {
						return {}
				  }
		componentOptions.provide = extendProvide(original, key, providedKey)
		return componentOptions
	}

export const ProvideReactive = (providedKey?: string) =>
	createDecorator((componentOptions, key) =>
		decorateProvideReactive(key, providedKey)(componentOptions)
	)

export const decorateInjectReactive =
	(key: string, providedKey?: string) => (componentOptions: ComponentOptions<Vue>) => {
		if (typeof componentOptions.inject === 'object') {
			if (Array.isArray(componentOptions.inject)) {
				throw new Error('Array inject is not allowed! in: ' + componentOptions.name)
			}
			componentOptions.inject.reactive = 'reactive'
		} else {
			componentOptions.inject = {
				reactive: 'reactive',
			}
		}
		if (!componentOptions.computed) {
			componentOptions.computed = {}
		}
		const injectedKey = providedKey || key
		componentOptions.computed[key] = function () {
			const obj = this.reactive
			return obj[injectedKey]
		}
		return componentOptions
	}

export const InjectReactive = (providedKey?: string) =>
	createDecorator((componentOptions, key) =>
		decorateInjectReactive(key, providedKey)(componentOptions)
	)

export const decorateProvide =
	(key: string, providedKey?: string) => (componentOptions: ComponentOptions<Vue>) => {
		const original =
			typeof componentOptions.provide === 'function'
				? (componentOptions.provide as () => object)
				: typeof componentOptions.provide === 'object'
				? function () {
						return componentOptions.provide
				  }
				: function () {
						return {}
				  }
		componentOptions.provide = function () {
			const originalObject = original.call(this)
			const finalKey = providedKey || key
			return {
				...originalObject,
				[finalKey]: this[key],
			}
		}
		return componentOptions
	}

export const Provide = (providedKey?: string) =>
	createDecorator((componentOptions, key) => decorateProvide(key, providedKey)(componentOptions))

export const decorateInject =
	(key: string, providedKey?: string, optional = false) =>
	(componentOptions: ComponentOptions<Vue>) => {
		const original =
			typeof componentOptions.inject === 'object'
				? Array.isArray(componentOptions.inject)
					? componentOptions.inject.reduce((acc, key) => {
							acc[key] = key
							return acc
					  }, {})
					: componentOptions.inject
				: {}
		original[key] = optional
			? {
					from: providedKey || key,
					default: undefined,
			  }
			: providedKey || key
		componentOptions.inject = original
		return componentOptions
	}

export const Inject = (providedKey?: string, optional = false) =>
	createDecorator((componentOptions, key) =>
		decorateInject(key, providedKey, optional)(componentOptions)
	)
