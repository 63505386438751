import { GetterTree, MutationTree, ActionTree } from 'vuex'

class State {
	requests = []
}

const getters: GetterTree<State, any> = {}

const mutations: MutationTree<State> = {
	pushPayloads(state: State, payload: { func: () => any; args: any[] }) {
		state.requests.push({ payload, result: null })
	},
	setResult(state: State, payload: { response: any; i: number }) {
		state.requests[payload.i].result = payload.response
	},
}

const actions: ActionTree<State, any> = {
	async call(context, payload) {
		context.commit('pushPayloads', payload)
		const i = context.state.requests.length - 1
		try {
			const response = await payload.func(...payload.args)
			context.commit('setResult', { response, i })
		} catch (e) {
			context.commit('setResult', { response: new Error(e.message), i })
		}
		return i
	},
}

export default {
	namespaced: true,
	state: new State(),
	mutations: mutations,
	actions: actions,
	getters: getters,
}
