import { Vue, Component } from 'vue-property-decorator'
import { LANG_COMMON } from '@/lang'
import { Language } from '@/constants'
import { ComponentOptions, InjectReactive } from '@/vue-extensions'
import { INJECTIONS } from '@/injections'
import { TranslatableLabel } from './types'

export type Translations = {
	[P in Language]: Record<string, string>
}

@Component({})
export default class I18n extends Vue {
	@InjectReactive(INJECTIONS.UI_LANGUAGE.CURRENT) langCode: Language

	get lang(): Record<string, string> {
		const lang = (this.$options as ComponentOptions<Vue>).lang || LANG_COMMON
		for (const l in lang) {
			lang[l] = { ...LANG_COMMON[l], ...lang[l] }
		}
		return (lang[this.langCode] || {}) as Record<string, string>
	}

	translate(translatableLabel: TranslatableLabel): string {
		return translatableLabel[this.langCode]
	}
}
