import BaseError from './BaseError'

export default class AttributeIsNotApplicableForClassError extends BaseError<{
	_class: string
	attribute: string
}> {
	httpCode = 429

	getMessage(): string {
		return `Attribute '${this.params.attribute}' not aplicable for class(es) ${this.params._class}`
	}
}
