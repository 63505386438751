import { Vue, Component } from 'vue-property-decorator'
import { Provide } from '@/vue-extensions'
import { DISPATCH, SUBSCRIBE } from '@/core/events/constants'
import { AppEvents, Listener, UnsubscribeFn } from '@/core/events/types'
import EventEmitter from '@/utils/event-emitter'

@Component({})
export class EventBusProvider extends Vue {
	eventEmitter = new EventEmitter<AppEvents>()

	@Provide(SUBSCRIBE) subscribe<K extends keyof AppEvents>(
		kind: K,
		listener: Listener<AppEvents[K]>
	): UnsubscribeFn {
		return this.eventEmitter.on(kind, listener)
	}

	@Provide(DISPATCH) dispatch<K extends keyof AppEvents>(kind: K, payload: AppEvents[K]) {
		this.eventEmitter.emit(kind, payload)
	}

	render() {
		return this.$slots.default
	}
}
