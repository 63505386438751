import { RouteConfig } from 'vue-router'

export const demoRoutes: RouteConfig[] = [
	{
		path: '/demo/attachments',
		component: () => import('./Attachments.vue'),
	},
	{
		path: '/demo/group',
		component: () => import('./GroupEditForm.vue'),
	},
	{
		path: '/demo/filter',
		component: () => import('@/views/demos/ObjectConditionForm.vue'),
	},
	{
		path: '/demo/rs-table',
		component: () => import('./ResizableTable.vue'),
	},
	{
		path: '/demo/inputs',
		component: () => import('./InputsDemo.vue'),
	},
]
