import {Language} from "@/constants";

export const getLang = () => {
  let lang = localStorage.getItem('agmir_agmir:contentLanguage')
  if (lang) {
    lang = JSON.parse(lang).id
  }
  return lang
}

export const getUiLang = () => {
  let lang = localStorage.getItem('agmir_uiLanguage')
  if (lang) {
    return  JSON.parse(lang).value
  } else {
    return Language.ru
  }
}