<template>
	<div class="notification">
		<div class="header">
			<div class="title">
				{{ title || lang.caution }}
			</div>
			<Cross @click="$emit('close')" />
		</div>
		<div class="content">
			{{ content }}
		</div>
	</div>
</template>

<script>
import Cross from '@/components/Common/Cross'
import I18n from '@/i18n.mixin'
import { Language } from '@/constants'

export default {
	name: 'Notification',
	components: { Cross },
	props: {
		title: {
			type: String,
		},
		content: {
			type: String,
			required: true,
		},
	},
	mixins: [I18n],
	lang: {
		[Language.en]: {
			caution: 'Caution',
		},
		[Language.ru]: {
			caution: 'Внимание',
		},
	},
	mounted() {
		if (this.title === 'Проверка лицензии' || this.title === 'License verification') return
		setInterval(() => {
			this.$emit('close')
		}, 20 * 1000)
	},
}
</script>

<style scoped lang="scss">
.notification {
	display: flex;
	flex-direction: column;

	background-color: #ffffff;
	box-shadow: rgba(0, 0, 0, 0.2) 0 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px,
		rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;

	.header {
		padding: 7px 10px;
		display: flex;

		border-bottom: 1px solid #dfdfdf;

		.title {
			flex-grow: 1;
		}
	}

	.content {
		padding: 7px 10px;
	}
}
</style>
