/*

Usage:

ES6:
import ve from "vue-debounce.js"
Vue.use( ve )

ES5:
var ve = require("vue-debounce.js")
Vue.use( ve )

template:
<input
  v-bind:value="val"
  v-debounce:input.500="( v ) => { this.val = v; }"
/>

or

<input
  :value="arr[ i ]"
  v-debounce:input.300.trim="( v ) => { $set( arr, i, v ); }"
/>


v-debounce:keyup="..." <= default event is "input", but it can be changed
v-debounce.trim="..."

or can be used an event

<input
  v-debounce:keyup.trim @debouncedKeyup="$set( array, i, $event );"
/>

or

<input
  v-debounce:keyup.trim @debouncedKeyup="$set( array, i, $event );"
/>

*/

const vueDebounce = {}

vueDebounce.install = function (Vue) {
	function emit(vnode, name, data) {
		const handlers =
			(vnode.data && vnode.data.on) || (vnode.componentOptions && vnode.componentOptions.listeners)
		if (handlers && handlers[name]) {
			handlers[name].fns(data)
		}
	}

	Vue.directive('debounce', {
		bind(el, binding, vnode) {
			let delay = 0
			let trim = false

			if (binding.modifiers) {
				for (const t in binding.modifiers) {
					if (binding.modifiers.hasOwnProperty(t)) {
						if (/^\d+$/.test(t)) {
							delay = parseInt(t, 10)
						}
						if (t === 'trim') {
							trim = true
						}
					}
				}
			}
			if (!delay) {
				delay = 1000
			}

			if (!el._debounce_) {
				el._debounce_ = []
			}

			const dbc = {
				tid: null,
				delay,
				trim,
				event: (binding.arg || 'input').toLowerCase(),
				onEvent: (e) => {
					if (el._debounce_ && el._debounce_.includes(dbc)) {
						if (dbc.tid) {
							clearTimeout(dbc.tid)
						}

						dbc.tid = setTimeout(() => {
							dbc.tid = null
							const v = dbc.trim ? el.value.trim() : el.value
							if (el.value !== v) {
								el.value = v
							}

							if (binding.value || binding.expression) {
								if (typeof binding.value === 'function') {
									binding.value.bind(vnode.context)(v, e)
								} else {
									new Function(binding.expression.replace(/^(")(.*?)\1$/, '$2')).bind(
										vnode.context
									)(v, e)
								}
							} else {
								emit(vnode, 'debounced' + dbc.event.charAt(0).toUpperCase() + dbc.event.slice(1), v)
							}
						}, dbc.delay)
					}
				},
			}

			el._debounce_.push(dbc)
			el.addEventListener(dbc.event, dbc.onEvent)
		},

		unbind(el) {
			if (el._debounce_) {
				for (const dbc of el._debounce_) {
					if (dbc.tid) {
						clearTimeout(dbc.tid)
					}
					if (dbc.event && dbc.onEvent) {
						el.removeEventListener(dbc.event, dbc.onEvent)
					}
				}

				delete el._debounce_
			}
		},
	})
}

if (typeof window !== 'undefined' && window.Vue) {
	window.Vue.use(vueDebounce)
}

export default vueDebounce
