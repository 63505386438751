import { Component, Mixins, Watch } from 'vue-property-decorator'
import { BaseError } from '~/shared'
import { CreateElement } from 'vue'

import NotificationContainer from '@/components/Common/Toast/NotificationContainer.vue'
import { ReactiveProps } from '@/utils/reactive-props'
import { InjectReactive, Provide } from '@/vue-extensions'
import { errorTypes } from '@/core/error-handling'
import { Language } from '@/constants'
import I18n from '@/i18n.mixin'
import { INJECTIONS } from '@/injections'

const translators: {
	[P in keyof typeof errorTypes]: {
		[L in Language]: (params: any) => string
	}
} = {
	ClassDoesNotExistsError: {
		[Language.ru]: (params) => `Класс ${params.id} не существует`,
		[Language.en]: (params) => `Class ${params.id} does not exist`,
	},
	ObjectDoesNotExistError: {
		[Language.ru]: (params) => `Объект ${params.id} не существует`,
		[Language.en]: (params) => `Object ${params.id} does not exist`,
	},
	ClassHasNotAttributeError: {
		[Language.ru]: (params) =>
			`Класс ${params._class.name}(${params._class.id}) не имеет атрибута ${params.id}`,
		[Language.en]: (params) =>
			`Class ${params._class.name}(${params._class.id}) has not attribute ${params.id}`,
	},
	ClassNotSelectedError: {
		[Language.ru]: () => 'Класс не выбран! Необходимо сначала выбрать класс!',
		[Language.en]: () => 'No class has been selected! Please, select class!',
	},
	NotAuthenticatedError: {
		[Language.ru]: () => 'Необходима аутентификация!',
		[Language.en]: () => 'Authentication required!',
	},
	AuthInvalidCredentialsError: {
		[Language.ru]: () => 'Неверные имя пользователя или пароль!',
		[Language.en]: () => 'Wrong username or password!',
	},
	CacheBuildError: {
		[Language.ru]: () => 'Ошибка построения кэша. Пожалуйста, сообщите администратору.',
		[Language.en]: () => 'Cache building error',
	},
	UnknownServerError: {
		[Language.ru]: () =>
			'Произошла непредвиденная ошибка сервера. Пожалуйста, сообщите администратору.',
		[Language.en]: () => 'An unexpected server error occurred. Please, notify the administrator.',
	},
	NetworkError: {
		[Language.ru]: () =>
			'Ошибка сети. Пожалуйста, проверьте наличие доступа к Интернету на вашем устройстве.',
		[Language.en]: () => 'Network error. Please check if your device has access to the Internet.',
	},
	FeatureNotSupportedError: {
		[Language.ru]: () => 'К сожалению, эта функция недоступна.',
		[Language.en]: () => 'Sorry, this feature is not available.',
	},
	RejectedByUserError: {
		[Language.ru]: () => 'Отклонено пользователем',
		[Language.en]: () => 'Rejected by user',
	},
	AttributeIsNotApplicableForClassError: {
		[Language.ru]: ({ _class, attribute }) =>
			`Атрибут "${attribute}" не может быть применён к классу ${_class}`,
		[Language.en]: ({ _class, attribute }) =>
			`Attribute "${attribute}" is not applicable to class ${_class}`,
	},
	ProposedToConfirmError: {
		[Language.ru]: () => 'Ваши изменения сохранены, но требуют подтверждения модератора',
		[Language.en]: () => 'Your changes are saved, but moderator` confirmation is required',
	},
	ClassAlreadyExistsError: {
		[Language.ru]: (params) => `Класс c идентификатором "${params.id}" уже существует`,
		[Language.en]: (params) => `Class by identifier: "${params.id}" already exists`,
	},
	AttributeAlreadyExistsError: {
		[Language.ru]: (params) => `Атрибут c идентификатором "${params.id}" уже существует`,
		[Language.en]: (params) => `Attribute by identifier: "${params.id}" already exists`,
	},
	MdmError: {
		[Language.ru]: (params) => 'Ошибка MDM: ' + params.message,
		[Language.en]: (params) => 'MDM Error: ' + params.message,
	},
	BackingServiceUnavailableError: {
		[Language.ru]: (params) => `Сервис "${params.serviceName}" недоступен`,
		[Language.en]: (params) =>
			`The service with name "${params.serviceName}" is currently unavailable`,
	},
	SocketError: {
		[Language.ru]: (params) =>
			`Ошибка создания сокета. Тип: ${params.previous && params.previous.name}, сообщение: ${
				params.previous && params.previous.message
			}. ${params.connectionId}`,
		[Language.en]: (params) =>
			`Socket creating error. Type: ${params.previous && params.previous.name}, message: ${
				params.previous && params.previous.message
			}. ${params.connectionId}`,
	},
	WrongImportHeadersError: {
		[Language.ru]: () => 'Неверные заголовки документа.',
		[Language.en]: () => 'Неверный заголовки документа.',
	},
	UndefinedMdmError: {
		[Language.ru]: () => 'Неуточнённая ошибка MDM',
		[Language.en]: () => 'Unknown MDM error',
	},
	MdmTimeoutError: {
		[Language.ru]: () => 'Запрос к MDM прекращён по тайм-ауту',
		[Language.en]: () => 'MDM request has terminated by timeout',
	},
	NoPrefixesError: {
		[Language.ru]: () => 'Для текущей конечной точки отсутствуют префиксы',
		[Language.en]: () => 'There are no prefixes set for current endpoint',
	},
}

const infoMessages = {
	SelectRoute: {
		[Language.ru]: {
			title: 'Недостаточно прав',
			content: 'Выберите маршрут согласования',
		},
		[Language.en]: {
			title: 'Not enough rights',
			content: 'Choose an approval route',
		},
	},
	RouteCreated: {
		[Language.ru]: {
			title: 'Недостаточно прав',
			content: 'Cоздан маршрут для согласования',
		},
		[Language.en]: {
			title: 'Not enough rights',
			content: 'Route for approval created',
		},
	},
	ObjectAlreadyExists: {
		[Language.ru]: {
			title: 'Задайте другой идентификатор',
			content: 'Идентификатор уже используется другой сущностью',
		},
		[Language.en]: {
			title: 'Set a different Identifier',
			content: 'Identifier is already used by other entity',
		},
	},
	ImportHasError: {
		[Language.ru]: {
			title: 'Во время импорта возникла ошибка(и)',
			content: 'Откройте скачанный файл с результатами',
		},
		[Language.en]: {
			title: 'Error(s) occurred during import',
			content: 'Open the downloaded file with the results',
		},
	},
	LicenseTechSupportExpire: {
		[Language.ru]: {
			title: 'Проверка лицензии',
			content: 'Срок действия тех. поддержки скоро закончится. Обратитесь в компанию "ТриниДата".',
		},
		[Language.en]: {
			title: 'License verification',
			content: 'Tech support will expire. Contact "TriniData".',
		},
	},
	LicenseTechSupportDeadline: {
		[Language.ru]: {
			title: 'Проверка лицензии',
			content: 'Срок тех. поддержки закончился. Обратитесь в компанию "ТриниДата".',
		},
		[Language.en]: {
			title: 'License verification',
			content: 'The support period has expired. Contact "TriniData".',
		},
	},
	LicenseNoResponseKey: {
		[Language.ru]: {
			title: 'Проверка лицензии',
			content: 'Отсутствует ключ-ответ лицензии. Обратитесь в компанию "ТриниДата".',
		},
		[Language.en]: {
			title: 'License verification',
			content: 'No license response key. Contact "TriniData".',
		},
	},
	LicenseKeysMismatch: {
		[Language.ru]: {
			title: 'Проверка лицензии',
			content: 'Ключ-ответ не соответствует ключ-запросу. Обратитесь в компанию "ТриниДата".',
		},
		[Language.en]: {
			title: 'License verification',
			content: 'Response key does not match the request key. Contact "TriniData".',
		},
	},
	LicenseInvalidKey: {
		[Language.ru]: {
			title: 'Проверка лицензии',
			content:
				'Один из ключей не содержит одно или несколько полей. Обратитесь в компанию "ТриниДата".',
		},
		[Language.en]: {
			title: 'License verification',
			content: 'One of the keys does not contain one or more fields. Contact "TriniData".',
		},
	},
	LicenseInvalidAppId: {
		[Language.ru]: {
			title: 'Проверка лицензии',
			content: 'Ключ-запрос не соответствует ПО. Обратитесь в компанию "ТриниДата".',
		},
		[Language.en]: {
			title: 'License verification',
			content: 'Request key does not match the software. Contact "TriniData".',
		},
	},
	LicenseKeysEquals: {
		[Language.ru]: {
			title: 'Проверка лицензии',
			content: 'Ключ-запрос не может совпадать с ключ-ответом. Обратитесь в компанию "ТриниДата".',
		},
		[Language.en]: {
			title: 'License verification',
			content: 'The request key cannot match the response key. Contact "TriniData".',
		},
	},
	UnknownLicenseError: {
		[Language.ru]: {
			title: 'Проверка лицензии',
			content: 'Неизвестная ошибка проверки лицензии. Обратитесь в компанию "ТриниДата".',
		},
		[Language.en]: {
			title: 'License verification',
			content: 'Unknown license verification error. Contact "TriniData".',
		},
	},
	BeforeConfirmObjectNotExists: {
		[Language.ru]: (id) =>
			`До подтверждения предлагаемых изменений в Маршруте согласования объект ${id} не существует`,
		[Language.en]: (id) =>
			`Until the proposed changes are confirmed in Approval route, the ${id} object does not exist`,
	},
	IgnoredClassesAddOk: {
		[Language.ru]: () => 'Список классов для скрытия успешно обновлён.',
		[Language.en]: () => 'The list of classes to hide has been successfully updated.',
	},
	IgnoredClassesClearOk: {
		[Language.ru]: () => 'Список классов для скрытия очищен.',
		[Language.en]: () => 'The list of classes to hide has been cleared.',
	},
}

type Message = { id: string; payload?: any }

@Component({
	lang: {
		[Language.ru]: {
			relogin: 'Авторизоваться в другой учетной записи?',
		},
		[Language.en]: {
			relogin: 'Sign in with a different account?',
		},
	},
})
export default class ErrorHandler extends Mixins(ReactiveProps, I18n) {
	notifications: any[] = []

	@Provide('handleError') handleError(e: Error) {
		if (e.message === 'ERR_CANCELED') {
			return
		}
		console.error(e)
		// const notificationData = this.getNotificationData(e)
		// console.log(notificationData)
		this.$store.commit('notifications/toast', {id: e.name})
		// this.notifications.push(notificationData)
	}

	getNotificationData(e: Error) {
		if (e instanceof BaseError) {
			return {
				title: this.lang.error,
				content: this.getTranslatedMessage(e),
			}
		} else {
			return {
				title: this.lang.error,
				content: e.message,
			}
		}
	}

	get storeNotifications(): Message[] {
		return this.$store.state.notifications.notifications
	}

	@Watch('storeNotifications')
	onStoreNotificationsChanged(v: Message[]) {
		if (v.length) {
			this.notifications.push(
				...v.map((e) => {
					const v = infoMessages[e.id][this.currentUiLanguage]
					return typeof v === 'function' ? v(e.payload) : v
				})
			)
			this.$store.commit('notifications/clear')
		}
	}

	@InjectReactive(INJECTIONS.UI_LANGUAGE.CURRENT) currentUiLanguage: Language

	getTranslatedMessage<E extends BaseError<any>>(error: E) {
		const translator =
			translators[(error as unknown as { name: string }).name]?.[this.currentUiLanguage]
		if (translator) {
			return (
				`${translator(error.params)} ${(error.params.requestId ? `\r\n ${error.params.requestId}` : '')}`
						// translator(error.params) + (error.params.requestId ? '\r\n' + error.params.requestId : '')
			)
		} else {
			return error.message
		}
	}

	render(h: CreateElement) {
		return h('div', [
			this.$slots.default,
			h(NotificationContainer, {
				props: this.propsReactive<ErrorHandler>({
					notifications: 'notifications',
				}),
				on: {
					close: (idx) => this.notifications.splice(idx, 1),
				},
			}),
		])
	}
}
