import { Api } from '~/shared'

import { UserCredentials } from '@/types'
import { apiClient } from '@/api/client'

export const login = async (credentials: UserCredentials): Promise<Api.User> => {
	const response = await apiClient.post(
		'/auth/login',
		{ ...credentials, login: credentials.username },
		{
			headers: {
				'content-type': 'application/json',
			},
		}
	)
	return response.data
}

export const logout = async (): Promise<void> => {
	await apiClient.post('/auth/logout')
}

export const check = async (): Promise<{ user: Api.User; access: boolean }> => {
	const response = await apiClient.get('/auth/check')
	return response.data
}
