import { GetterTree, MutationTree, ActionTree } from 'vuex'
import { apiClient } from '@/api/client'
import {keyErrors} from "@/notifications";

class State {
	endpoint = null
	object = null
	operation = null
	options = []
}

const getters: GetterTree<State, any> = {}

const mutations: MutationTree<State> = {
	reset(state) {
		state.endpoint = null
		state.object = null
		state.operation = null
		state.options = []
	},
	setData(state, payload) {
		state.endpoint = payload.endpoint
		state.object = payload.object
		state.operation = payload.operation
	},
	setOptions(state, payload) {
		state.options = payload
	},
}

const actions: ActionTree<State, any> = {
	async load(context, payload) {
		const { endpoint, operation, object } = payload
		const response = await apiClient.get(
			`endpoints/${endpoint}/acceptRoutes/${operation}/${encodeURIComponent(object)}`
		)
		const routes = response.data
		context.commit('setData', payload)
		context.commit('setOptions', routes)
	},
	async submit(context, route) {
		const { endpoint, operation, object } = context.state
		await apiClient.post(
			`endpoints/${endpoint}/acceptRoutes/${operation}/${encodeURIComponent(object)}`,
			{ route }
		)
		context.commit('notifications/toast', {id:keyErrors.RouteCreated}, { root: true })
		context.commit('reset')
	},
}

export default {
	namespaced: true,
	state: new State(),
	mutations: mutations,
	actions: actions,
	getters: getters,
}
