<template>
	<svg :x="x" :y="y"
	     :width="width" :height="height"
	     viewBox="0 0 48 48">
		<rect x="3" y="15" width="25" height="25" rx="5"/>
		<path d="M15,9 v-3 h25 v27 h-7" stroke-linecap="round" stroke-linejoin="round"/>
	</svg>

</template>

<script>
export default {
	name: "Copy",
	props: {
		x: {
			type: Number,
			default: 0
		},
		y: {
			type: Number,
			default: 0
		},
		height: {
			type: Number,
			default: 25
		},
		width: {
			type: Number,
			default: 25
		}
	}
}
</script>

<style scoped lang="scss">
svg {
	rect, path {
		fill: none;
		stroke: var(--button);
		stroke-width: 4px;
	}

	&:hover{
		rect, path {
			stroke: var(--button_hover);
		}
	}
}
.black {
	rect, path {
		stroke: black;
	}

	&:hover{
		rect, path {
			stroke: black;
		}
	}
}
</style>