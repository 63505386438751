import { v4 as uuid } from 'uuid'
import './polyfills'

window._CONNECTION_ID_ = uuid()

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import vd from '@/components/vue-debounce.js'
Vue.use(vd)

import frag from 'vue-frag'
Vue.directive('frag', frag)

import vSelect from 'vue-select'
import '@/assets/styles/vue-select/vue-select.scss'
Vue.component('v-select', vSelect)

import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import '@/assets/styles/toast.scss'

Vue.use(Toast, {
	position: 'bottom-right',
	timeout: 20_000,
	closeOnClick: false,
	pauseOnFocusLoss: true,
	pauseOnHover: true,
	draggable: false,
	showCloseButtonOnHover: false,
	closeButton: 'button',
	hideProgressBar: true,
	icon: false,
	rtl: false,
	transition: 'Vue-Toastification__fade',
})

Vue.config.productionTip = false
new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount('#app')
