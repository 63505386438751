<template>
	<span class="cross" @click="$emit('click')" />
</template>

<script>
export default {
	name: 'Cross',
}
</script>

<style scoped>
.cross {
	display: block;
	position: relative;
	width: 23px;
	height: 23px;
	cursor: pointer;
}

.cross:after,
.cross:before {
	content: '';
	height: 1px;
	width: 100%;
	background-color: #8f8f8f;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
}

.cross:after {
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
}

.cross:before {
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg);
}

.cross:hover:after,
.cross:hover:before {
	background-color: #fc8215;
}
</style>
