import BaseError from './BaseError'

export default class MdmError extends BaseError<{
	message: string
}> {
	httpCode = 500

	getMessage(): string {
		return 'MDM Error: ' + this.params.message
	}
}
